import React, { useState } from 'react';
import { css } from "@emotion/core"
import { theme } from "../Styles/theme"
import styled from "@emotion/styled"
import { Section } from "../Styles/section"
import { HeadingTertiary } from "../Styles/headers"
import { SectionHero, SectionTagLine } from "../Styles/text"

const HeaderSection = styled.div`
  color: ${theme.colors.grey900};
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  margin-top: 5rem;
  line-height: 1.4;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 2rem;
    padding-top: 0.2rem;
    h3 {
      line-height: 1.4;
    }
  }
`

const BtnMedium = css`
  background: ${theme.colors.primary};
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text200};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`
const Form = styled.form`
  display: flex;
  padding: 1rem 0.2rem 1rem;
  width: 100%;
  max-width: 800px;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`
const FormGroup = styled.div`
  input {
    font-size: 1rem;
    text-align: start;
    width: 100%;
    padding: 1rem;
    &:focus {
      outline: none !important;
      border: 2px solid #8d9aa7;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
    margin-bottom: 1rem;
  }
`
const FormSubmit = styled.button`
  width: 31%;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px;
  background: ${theme.colors.primary};
  color: white;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`

const SubscriptionForm = () => {
  const [status, setStatus] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  //FORM_URL should be the same as the form action url pointed out above
  const FORM_URL = `https://app.convertkit.com/forms/5418857/subscriptions`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    try {
      const response = await fetch(
        FORM_URL,
        {
          method: 'post',
          body: data,
          headers: {
            accept: 'application/json',
          },
        }
      );
      setName('');
      setEmail('');
      const json = await response.json();
      console.log(json)
      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }
    } catch (err) {
      setStatus('ERROR');
      console.log(err);
    }
  };

  const handleNameChange = event => {
    const { value } = event.target;
    setName(value);
  }
  const handleEmailChange = event => {
    const { value } = event.target;
    setEmail(value);
  }
  return (
    <Section className="sub" backgroundColor="main">
      <HeaderSection>
        <HeadingTertiary style={{ margin: "0px 0 4px" }}>
          Tech Career, Entrepreneurship, and Personal Growth
        </HeadingTertiary>
        <SectionHero>
          Looking to break into the tech industry and land your first dev role? Or an experienced dev ready to ditch the 9-to-5 and kickstart your own thing – freelancing, running an agency, or creating a personal brand?
        </SectionHero>
        <SectionHero>
          Been there, done that.
        </SectionHero>
        <SectionHero>
          Get no-nonsense guides, playbooks, tested strategies, and real success stories every week.
        </SectionHero>

        {status === 'SUCCESS' && <small style={{ paddingLeft: "1rem", color: "green" }}>Please go confirm your subscription!</small>}
        {status === 'ERROR' && <small style={{ paddingLeft: "1rem", color: "red" }}>Oops, Something went wrong! try again.</small>}

        <Form className="sub__form"
          action={FORM_URL}
          method="post"
          onSubmit={handleSubmit}
        >
          <FormGroup>
            {/* <label>First name</label> */}
            <input
              type="text"
              key="ipsum"
              aria-label="Your name"
              name="first_name"
              id="input-name"
              placeholder="First name"
              value={name}
              onChange={handleNameChange}
              required
            />
          </FormGroup>
          <FormGroup>
            {/* <label>Email Address</label> */}
            <input
              type="email"
              key="ipsum"
              aria-label="Your email"
              name="email_address"
              id="input-email"
              placeholder="Your email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </FormGroup>
          <FormSubmit type="submit" css={BtnMedium} style={{ fontSize: "1rem" }}>
            Read For Free
          </FormSubmit>
        </ Form>

      </HeaderSection>
    </Section>
  );
};

export default SubscriptionForm
