import React, { Component, useState } from "react"
import { css } from "@emotion/core"
import { SectionHero } from "../Styles/text"

const QuoteBox = css`
  text-align: center;
  margin: 3rem 0;
`;
const QuoteText = css`
  font-size: 1.5rem;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  line-height: 1.5;
`;
const QuoteImage = css`
  border-radius: 50%;
`
const QuoteSource = css`
  margin-top: -1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const QuoteName = css`
  text-transform: capitalize;
  margin-left: .5rem;
`;

export default function ReviewsItem(props) {
  const {name, image, quote, id} = props.review.node;
  return (
    <blockquote css={QuoteBox} key={id}>
      <p css={QuoteText}>"{quote}"</p>
      <cite css={QuoteSource}>
        <img css={QuoteImage} width="40" height="40" alt={name} src={image} loading="lazy" /> 
        <span css={QuoteName}>{name}</span>
      </cite>
    </blockquote>
  )
}