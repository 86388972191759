import React from "react"
import { theme } from "../Styles/theme"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import Header from "../Components/header"
import Layout from "../Components/layout"
import SubscriptionForm from "../Components/subscriptionForm"
import Brands from "../Components/brands"
import Cta from "../Components/cta"
import Products from "../Components/Products"
import Testimonials from "../Components/Reviews"
import { GlobalStyles } from "../Styles/global"

export default () => (
  <ThemeProvider theme={theme}>
    <Global styles={GlobalStyles} />
    <Layout>
      <SubscriptionForm />
      <Brands />
      {/* <Cta /> */}
      {/* <Products /> */}
      {/* <Testimonials /> */}
    </Layout>
  </ThemeProvider>
)
