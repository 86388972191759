import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { HeadingSecondary, HeadingTertiary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"
import { Section } from "../Styles/section"
import { theme } from "../Styles/theme"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ReviewsItem from './ReviewsItem'

export default function Reviews() {
  const data = useStaticQuery(graphql`
    query FreeCourseReviewsQuery {
      allFreeCourseReviewsJson {
        edges {
          node {
            id
            image
            name
            quote
          }
        }
      }
    }
  `)

  return (
    <Section backgroundColor="secondary" style={{"padding": "2rem 2rem"}}>
      <HeadingSecondary css={{ textAlign: "center" }}>People *really* like this course</HeadingSecondary>
      {data.allFreeCourseReviewsJson.edges.map((item, index) => (
        <ReviewsItem review={item} key={index} />
      ))}
    </Section>
  )
}
